import React, { useEffect } from 'react';

const BinotelWidget: React.FC = () => {
    useEffect(() => {
        const widgetHash = 'k0yhsretumfarwuqg18f';
        const gcw = document.createElement('script');
        gcw.type = 'text/javascript';
        gcw.async = true;
        gcw.src = `https://widgets.binotel.com/getcall/widgets/${widgetHash}.js`;

        const handleError: OnErrorEventHandlerNonNull = (
            event: Event | string,
            source?: string,
            lineno?: number,
            colno?: number,
            error?: Error
        ) => {
            console.error('Помилка при завантаженні скрипта Binotel:', event, error);
        };
        gcw.onload = () => console.log('Скрипт Binotel успішно завантажений');
        gcw.onerror = handleError;

        document.head.appendChild(gcw);

        return () => {
            if (document.head.contains(gcw)) {
                document.head.removeChild(gcw)
            }
        };
    }, []);

    return <div id="binotel-widget-container" />;
};

export default BinotelWidget;
